<template>
  <div class="card">
    <div class="card-header">
      <h4>Form Agency</h4>
    </div>
    <form @submit.prevent="ladiesInsert">
      <div class="card-body">
        <div class="form-group">
          <label>Ladies Type</label>
          <model-list-select
            :list="ladies_types"
            v-model="form.ladies_types_id"
            option-value="id"
            option-text="name"
            placeholder="Select Type"
          >
          </model-list-select>
          <small class="text-danger" v-if="errors.ladies_types_id">{{
            errors.ladies_types_id[0]
          }}</small>
        </div>
        <div class="form-group">
          <label>Fee</label>
          <money v-model="form.fee" class="form-control"></money>
          <small class="text-danger" v-if="errors.fee">{{
            errors.fee[0]
          }}</small>
        </div>
      </div>
      <div class="card-footer text-right">
        <button
          class="btn btn-primary mr-1"
          type="submit"
          :disabled="isSubmitting"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import swal from "sweetalert";
import { apiGet, apiPost } from "../../services/api";
export default {
  data() {
    return {
      form: {
        agency_ladies_id: this.$route.params.id,
        ladies_types_id: "",
        fee: 0,
      },
      isSubmitting: false,
      ladies_types: [],
      errors: [],
    };
  },
  mounted() {
    this.getType();
  },
  methods: {
    getType() {
      apiGet("ladies_type").then((result) => {
        this.ladies_types = result.data.data;
      });
    },
    ladiesInsert() {
      apiPost("agency_ladies_fee/store", this.form)
        .then(() => {
          this.$noty.success("Your Agency Ladies Fee has been saved!");
          this.form.ladies_types_id = "";
          this.form.fee = 0;
          this.$parent.getAgencyFee();
        })
        .catch((error) => {
          console.log(error);
          this.errors = error.response.data;
          swal("Error!", error.response.message, "error");
          this.isSubmitting = false;
        });
    },
  },
};
</script>
